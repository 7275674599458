<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Editar proveedor
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <proveedor-form :values="form"></proveedor-form>
                        </v-form>
                    </v-col>
                </v-row>

            </div>
			<v-row justify="center" align="end">
				<v-col cols="12" md="5" class="text-right">
					<v-btn class="full-width-btn" tile text color="primary" to="/proveedores">Cancelar</v-btn>
					<v-btn color="secondary" class="ml-md-2 mt-3 mt-md-0 full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
            <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>

<script>
import ProveedorForm from "./Form.vue";
export default {
    components: {
        'proveedor-form': ProveedorForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            form: {
                 name: '',
                rfc: '',
                contact: '',
                phone: '',
                email: '',
                origin: '',
                type_business: 'single',
                business: [],
            },
            
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index()
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
        },
        index(){
			this.loading = true
            this.$http.get(this.$store.state.apiRoute+'/suppliers/'+this.id+'/edit')
            .then((response) => {
                this.form = response.data
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.loading = false
            })
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){
                var form = JSON.parse(JSON.stringify(this.form))
                this.$http.put(this.$store.state.apiRoute+'/suppliers/'+this.id+'/update', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha actualizado correctamente el proveedor <b>'+this.form.name+'</b>'
                    this.$router.push('/proveedores')
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>