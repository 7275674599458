<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Editar cotización
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <v-form ref="form" @submit.prevent="save">
                            <cotizacion-form :values="form" :rules="rules" :edition="true"></cotizacion-form>
                        </v-form>
                    </v-col>
                </v-row>

            </div>
			<v-row justify="center" align="end">
				<v-col cols="12" md="8" class="text-right">
					<v-btn class="full-width-btn" tile text color="primary" :to="{ name: 'Cotizaciones' }">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-md-2 ml-0 mt-md-0 mt-3 full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
            <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>

<script>
/* eslint-disable */
import CotizacionForm from "./Form.vue";

export default {
    components: {
        'cotizacion-form': CotizacionForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            form: {
                client_id: '',
                building_id: '',
                date: '',
                conditions: '',
                products: [],
                reference: '',
                discount: '',
            },
            rules: {
                amount: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]{0,3}?$"))
                            return 'Solo números válidos';
                        if(v!= null && parseInt(v) > 100)
                            return 'Solo números válidos';
                        
                        
                        return true;
                    }
                ],
                client_id: [
                    v => !!v || 'Cliente es requerido'
                ],
                building_id: [
                    v => !!v || 'Obra es requerido'
                ],
                date: [
                    v => !!v || 'Fecha es requerido'
                ],
                conditions: [
                    // v => !!v || 'Condiciones es requerido'
                ],

                name: [
                    v => !!v || 'Nombre es requerido'
                ],
                price: [
                    v => !!v || 'Precio es requerido',
                    v => /^(\d*\.)?\d+$/.test(v) || 'Precio debe ser númerico'
                ],
                reference: [
					v => !!v || 'Referencia es requerida',
					v => /^[0-9]*$/.test(v) || 'Solo números',
					v => (v.length == 0 || v.length <= 4) || 'no'
				]
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index()
    },
	methods: {
		validate () {
            console.log(this.form)
			return this.$refs.form.validate()
        },
        index(){
            this.$http.get(this.$store.state.apiRoute+'/quotes/'+this.id+'/edit')
            .then((response) => {
                this.form = {
                    client_id: response.data.data.client_id,
                    building_id: response.data.data.building_id,
                    date: response.data.data.date.split('/').reverse().join('-'),
                    conditions: response.data.data.conditions,
                    reference: response.data.data.reference+'',
                    discount: response.data.data.discount??'',
                    products: response.data.variants.map((x) => {
                        return {
                            big_product_id: x.big_product_id,
                            product: x.product,
                            quantity: x.quantity,
                            amount: x.amount,
                            subtotal: parseFloat(x.quantity * x.amount),
                            actions: ''
                        }
                    })
                }                
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false
            })
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){      
                var form = this.form
                form.products = this.form.products.map((x) => {
                    return {
                        big_product_id: x.big_product_id,
                        quantity: x.quantity,
                        amount: x.amount
                    }
                })
                
                
                this.$http.put(this.$store.state.apiRoute+'/quotes/'+this.id+'/update', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado actualizada la cotización <b>'+this.id+'</b>'
                    this.$router.push({ name: 'Cotizaciones' })
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                console.log(this.$refs.form)
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>