<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="4">
						<v-text-field solo flat outlined dense v-model="tableProps.search" label="Buscar..." clearable append-icon="mdi-magnify" hide-details></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler"></modal>

	</div>
</template>

<script>
export default {
	data(){
		return {
            actions: [
                {
                    name: 'Detalle',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-information'
                    },
                    disabled: false,
                    type: 'redirect',   //  method, external, redirect
                    action: '',
                    permissions: 'purchaseOrders.show'
                },
                {
                    name: 'Editar',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-pencil'
                    },
                    disabled: false,
                    type: 'redirect',   //  method, external, redirect
                    action: '',
                    permissions: 'purchaseOrders.update'
                },
                {
                    name: 'Registrar pago',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-credit-card-outline'
                    },
                    disabled: false,
                    type: 'redirect',   //  method, external, redirect
                    action: '',
                    permissions: 'purchaseOrders.create'
                },
                {
                    name: 'Imprimir',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-printer'
                    },
                    disabled: false,
                    type: 'external',   //  method, external, redirect
                    action: '',
                    permissions: 'purchaseOrders.show'
                },
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'removeRecord',
                    permissions: 'purchaseOrders.delete'
				},
			],
			tableProps: {
				headers: [
					{
						text: 'Folio',
						align: 'left',
						value: 'sku',
						class: 'primary--text text-uppercase'
					},
					// {
					// 	text: 'Referencia',
					// 	align: 'left',
					// 	value: 'reference',
					// 	class: 'primary--text text-uppercase'
					// },
					{
						text: 'Requisición',
						align: 'left',
						value: 'requisition',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Proveedor',
						align: 'left',
						value: 'supplier',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Empresa',
						align: 'left',
						value: 'business_text',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Fecha',
						align: 'left',
						value: 'date',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Subtotal',
						align: 'left',
						value: 'total',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Iva',
						align: 'left',
						value: 'iva',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Pago',
						align: 'left',
						value: 'total_payment',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'primary--text text-uppercase'
					}
				],
				items: [],
				search: ''
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
			numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2})
		}
	},
	mounted(){
		this.index()
	},
	methods: {
		method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.$store.state.overlay = true
			
			this.$http.get(this.$store.state.apiRoute+'/purchaseOrders')
			.then((response) => {
				this.tableProps.items = response.data.map((x) => {
					x['actions'] = JSON.parse(JSON.stringify(this.actions))

					x['total'] = '$'+this.numberFormat.format(x.total)
					x['total_payment'] = x.total_payment === null ? `$0` : `$${this.numberFormat.format(x.total_payment)}`;

					x.actions[0].action = { name: 'OrdenesDeCompraDetalles', params: { id: x.sku } }
					x.actions[1].action = { name: 'OrdenesDeCompraEdicion', params: { id: x.sku } }
					x.actions[2].action = { name: 'OrdenesDeCompraPagos', params: { id: x.sku } }
					x.actions[3].action = this.$store.state.apiRoute+'/purchaseOrders/'+x.sku+'/pdf'

					x['parameters'] = { id: x.sku }
					
					return x
				})
			})
			.catch(error => {
				error
			})
			.finally(() => {
				this.$store.state.overlay = false
			})
		},
		removeRecord(parameters){
			this.modalProps = {
				visible: true,
				width: '500',
				title: 'Eliminar orden de compra',
				text: '¿Desea continuar?',
				buttons: [
					{
						text: 'Cancelar',
						color: 'primary',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: {}
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'text-base-color mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: {
							id: parameters.id
						}
					}
				]
			}
			this.modalProps.visible = true
		},
		confirm(parameters){
			this.$http.delete(this.$store.state.apiRoute+'/purchaseOrders/'+parameters.id+'/delete')
			.then(() => {
				this.snackbar = {
					visible: true,
					color: 'green',
					text: 'Se eliminó la orden de compra',
					timeout: 3000
				}
				this.index()
			}).catch(error => {
                console.error(error);
				this.snackbar = {
					visible: true,
					color: 'red',
					text: 'Error al eliminar. Intenta de nuevo',
					timeout: 3000
				}
			})
			.finally(() => {
				this.modalProps.visible = false
			})
		},
		cancel(){
			this.modalProps.visible = false
		}
	},
}
</script>

<style>

</style>